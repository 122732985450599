<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ $t("shopping cart") }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="cartItems"
        :mobile-breakpoint="mbreakpoint"
        :expanded="expanded"
        show-expand
        single-expand
        sort-by="pos"
        :footer-props="{
          itemsPerPageOptions: [15, 50, -1],
        }"
        :hide-default-footer="itemsInCart <= 15"
      >
        <template v-slot:item.name="{ item }">
          <span>
            {{ item.name }}
            <hardware-shop-item-sale :item="item" class="ml-4" small />
          </span>
        </template>

        <template v-slot:item.price_recurring="{ item }">
          <div class="monospace text-no-wrap" v-if="item.price_recurring != 0">
            {{ formatMoney(item.price_recurring, "CHF") }}
          </div>
        </template>

        <template v-slot:item.price_fix="{ item }">
          <div class="monospace text-no-wrap" v-if="item.price_fix != 0">
            {{ formatMoney(item.price_fix, "CHF") }}
          </div>
        </template>

        <template v-slot:item.quantity="{ item }">
          <v-edit-dialog
            v-if="item.qty_max != 1"
            :return-value.sync="item.quantity"
            @save="setCart(item)"
            large
            :cancel-text="$t('cancel')"
            :save-text="$t('save')"
          >
            <div class="editable">
              <v-icon small>mdi-pencil-outline</v-icon>
              <div>{{ item.quantity }}</div>
            </div>
            <template v-slot:input>
              <validation-provider
                vid="quantity"
                :name="$t('quantity')"
                rules="required|numeric"
                v-slot="{ errors, valid, dirty, classes }"
                class="mt-2"
              >
                <v-text-field
                  v-model="item.quantity"
                  name="quantity"
                  :label="$t('number of items')"
                  :error-messages="errors"
                  :success="dirty && valid"
                  :class="classes"
                  type="number"
                />
              </validation-provider>
            </template>
          </v-edit-dialog>
          <span v-else> {{ item.quantity }} </span>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="text-no-wrap">
            <v-icon @click="removeCart(item)" class="mr-1">
              mdi-trash-can-outline</v-icon
            >
            <v-icon
              @click="minusCart(item)"
              :disabled="cart[item.id] <= 1 || minMinus(item)"
              class="mr-1"
            >
              mdi-minus-circle
            </v-icon>
            <v-icon @click="plusCart(item)" :disabled="maxPlus(item)">
              mdi-plus-circle
            </v-icon>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card
              elevation="0"
              class="mt-4 mb-4"
              light
              @click="
                $router.push({
                  name: 'hardware-details',
                  params: { itemId: item.id },
                })
              "
            >
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-img
                      v-if="item.image != null"
                      height="200px"
                      :src="item.image.replace('gfo-dev', 'gfo')"
                      :alt="item.name"
                      contain
                    />
                  </v-col>
                  <v-col cols="8">
                    <div
                      v-html="
                        item.description.length > descriptionPreviewSize
                          ? item.description.substring(
                              0,
                              descriptionPreviewSize
                            ) + '...'
                          : item.description
                      "
                    ></div>
                    <br />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </td>
        </template>

        <template v-slot:body.append>
          <tr v-if="shippingItem != null">
            <td>{{ shippingItem.name }}</td>
            <td></td>
            <td>
              <div class="monospace text-no-wrap text-end">
                {{ formatMoney(shippingItem.price_fix, "CHF") }}
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div class="font-weight-bold">
                {{ $t("total") }}
              </div>
            </td>
            <td>
              <div class="monospace text-no-wrap text-end font-weight-bold">
                {{ formatMoney(priceMonthly, "CHF") }}
              </div>
            </td>
            <td>
              <div class="monospace text-no-wrap text-end font-weight-bold">
                {{
                  formatMoney(
                    priceFix +
                      (shippingItem != null ? shippingItem.price_fix : 0),
                    "CHF"
                  )
                }}
              </div>
            </td>
            <td>
              <div class="monospace text-no-wrap text-end font-weight-bold">
                {{ itemsInCart + (shippingItem != null ? 1 : 0) }}
              </div>
            </td>
            <td colspan="2"></td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$router.push({ name: 'hardware-list' })">
        {{ $t("continue shopping") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="$router.push({ name: 'hardware-order' })"
        :disabled="itemsInCart <= 0 || $store.getters.isReadOnly"
        text
      >
        <v-icon class="mr-2">mdi-cash-register</v-icon>
        {{ $t("to checkout") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import formatMoney from "@/utils/mixins/formatMoney";
import HardwareShopItemSale from "./HardwareShopItemSale";
import { createNamespacedHelpers } from "vuex";
import isMobile from "@/utils/mixins/isMobile";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopCart",
  components: {
    HardwareShopItemSale,
  },
  mixins: [formatMoney, isMobile],
  data: function () {
    return {
      descriptionPreviewSize: 500,
      expanded: [],
    };
  },
  computed: {
    ...mapState(["items", "cart"]),
    ...mapGetters([
      "itemsInCart",
      "cartItems",
      "shippingItem",
      "priceMonthly",
      "priceFix",
      "maxPlus",
      "minMinus",
    ]),
    headers() {
      return [
        {
          text: this.$i18n.t("item"),
          value: "name",
        },
        {
          text: this.$i18n.t("price mntly"),
          value: "price_recurring",
          align: "end",
        },
        {
          text: this.$i18n.t("price one-time"),
          value: "price_fix",
          align: "end",
        },
        {
          text: this.$i18n.t("quantity"),
          value: "quantity",
          align: "end",
        },
        { text: "", value: "action", sortable: false },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  methods: {
    ...mapMutations(["plusCart", "minusCart", "setCart", "removeCart"]),
  },
};
</script>

<style scoped>
.editable {
  display: flex;
  align-items: center;
}
</style>

<i18n>
{
  "en": {
    "shopping cart": "Shopping cart",
    "item": "Item",
    "quantity": "Quantity",
    "min. number": "Min. number",
    "max. number": "Max. number",
    "price one-time": "Price one-time",
    "price mntly": "Price mntly",
    "price": "Price",
    "total": "Total",
    "to checkout": "To checkout",
    "continue shopping": "Continue shopping",
    "number of items": "Number of items",
    "save": "save",
    "cancel": "cancel"
  }, 
  "de": {
    "shopping cart": "Warenkorb",
    "item": "Artikel",
    "quantity": "Anzahl",
    "min. number": "Min. Anzahl",
    "max. number": "Max. Anzahl",
    "price one-time": "Preis einmalig",
    "price mntly": "Preis mtl.",
    "price": "Preis",
    "total": "Total",
    "to checkout": "Zur Bestellung",
    "continue shopping": "Weiter Einkaufen",
    "number of items": "Artikelanzahl",
    "save": "speichern",
    "cancel": "abbrechen"
  }
}
</i18n>
