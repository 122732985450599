<template>
  <v-tooltip top v-if="sale">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="secondary" :small="small">mdi-sale</v-icon>
    </template>
    <div v-for="(price, i) in graduatedPrices" :key="i">
      {{
        price.price_fix
          ? $t("from {qty_min} pieces {price_fix} CHF", price)
          : $t("from {qty_min} pieces {price_recurring} CHF", price)
      }}
    </div>
  </v-tooltip>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopItemSale",
  props: {
    item: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["getItemPrices"]),
    prices() {
      return this.getItemPrices(this.item);
    },
    graduatedPrices() {
      return this.prices.slice(1);
    },
    sale() {
      return this.graduatedPrices.length > 0;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "from {qty_min} pieces {price_fix} CHF": "from {qty_min} pieces {price_fix} CHF",
    "from {qty_min} pieces {price_recurring} CHF": "from {qty_min} pieces {price_recurring} CHF"
  }, 
  "de": {
    "from {qty_min} pieces {price_fix} CHF": "ab {qty_min} Stück {price_fix} CHF",
    "from {qty_min} pieces {price_recurring} CHF": "ab {qty_min} Stück {price_recurring} CHF"
  }
}
</i18n>
